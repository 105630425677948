import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy'

import { MaxIterationsVideo } from '../../../../app/_components/media/MaxIterationsVideo.client'
import { ButtonBar } from '../../atoms/ButtonBar'
import { sectionGapDesktop, sectionGapMobile, titleSpacing } from '../styles'

export const Body = styled.section`
  align-items: center;
  display: flex;
  min-height: 360px;
  margin-bottom: ${sectionGapMobile * 2}px;
  position: relative;

  ${fromMd} {
    min-height: 550px;
    margin-bottom: ${sectionGapDesktop}px;
  }
`

export const AssetWrap = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  & > img,
  & > picture > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const VideoMobile = styled(MaxIterationsVideo)`
  ${fromMd} {
    display: none;
  }
`

export const VideoDesktop = styled(MaxIterationsVideo)`
  ${untilMd} {
    display: none;
  }
`

export const Title = styled.div<{
  isUppercase: boolean
}>`
  ${titleSpacing}
  font-size: 20px;
  font-weight: bold;
  margin: 10px auto 0;
  max-width: 80%;

  ${fromMd} {
    font-size: 36px;
  }
`

export const Subtitle = styled.div<{
  isUppercase: boolean
}>`
  ${titleSpacing}
  font-size: 16px;
  font-weight: bold;
  margin: 10px auto 0;
  max-width: 70%;

  ${fromMd} {
    font-size: 18px;
  }
`

export const TextWrap = styled.div<{
  textColor: string
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
}>`
  align-items: center;
  box-sizing: border-box;
  color: ${o => o.textColor};
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  padding: 16px 24px;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${untilMd} {
    ${Title},
    ${Subtitle} {
      max-width: ${o => o.textContentMaxWidthMobile && `${o.textContentMaxWidthMobile}px`};
    }
  }

  ${fromMd} {
    padding: 24px 40px;

    ${Title},
    ${Subtitle} {
      max-width: ${o => o.textContentMaxWidthDesktop && `${o.textContentMaxWidthDesktop}px`};
    }
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const StyledButtonBar = styled(ButtonBar)`
  margin-top: 24px;

  ${fromMd} {
    margin-top: 36px;
  }
`
